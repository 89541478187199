const getters = {
  // Is the page loading
  isLoading: (state) => state.loading || false,
  // Is there error on the website loading
  isLoadingError: (state) => state.loadingError,
  // Last loading error
  lastError: (state) => state.lastError || '',
  photowallConfig: (state) => state.wallConfigData.wallConfig.photoWall || {},
  socialwallConfig: (state) => state.wallConfigData.wallConfig.socialWall || {},
  wallType: (state) => state.wallConfigData.wallConfig.type || '',
  sources: (state) => state.wallConfigData.wallConfig.sources || {},
  customCss: (state) => state.wallConfigData.mainCss || '',
};

export default getters;
