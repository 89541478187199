import { firebaseAction } from 'vuexfire';
import { version } from '../../../package.json';
import firebaseReference, { fbInit } from './FirebaseReference';

/**
 * @brief Store functions, mostly related to firebase
 * Most of the action will take a payload argument, it must be formmated like this :
 *  payload: {
 *   endpoint: '',    // the node name you want to bind
 *   objectName: '',  // Name of the object you want to bind, must be available in the state (optional in the action 'updateConfig')
 *   object: {},      // the bounded object (optional in the action 'bindConfig/Data')
 *  },
 */
const actions = {
  async getMedias({ getters, dispatch }, { since, onlyImages }) {
    let out = [];
    const promises = [];
    Object.values(getters.sources).forEach((source) => {
      promises.push(new Promise((resolve) => {
        (async () => {
          if (source.type === 'webapp' && source.webappModulePath)
            out = out.concat(await dispatch('getWebappMedias', {
              since,
              path: source.webappModulePath,
              withAttachments: onlyImages,
            }));
          if (source.type === 'photobooth')
            out = out.concat(await dispatch('getPhotoboothMedias', { since, photoboothID: source.photoboothAnimation }));
          if (source.type === 'userdata')
            out = out.concat(await dispatch('getUserdataMedias', { since, data: source.userData || '' }));
          resolve();
        })();
      }));
    });
    await Promise.all(promises);
    return out;
  },

  async getPhotoboothMedias({ _ }, { photoboothID, since })
  {
    const out = [];
    const url = `https://europe-west1-captag-events.cloudfunctions.net/data/docs/${firebaseReference.eID}`;
    const query = {};
    if (since) query.since = since;
    if (photoboothID) query.category = photoboothID;
    const [data, err] = await firebaseReference.fetchAPI(url, { query, addTokenInQuery: true });
    if (!data || err)
    {
      console.error(`Error while fetching docs: ${err}`);
      return [];
    }
    (Object.values(data.docs || {})).forEach((media) => {
      out.push({
        link: media.link || '',
        attachment: media.link || '',
        message: '',
        avatar: '',
        author: media.updatedBy || '',
        createdDate: media.createdDate || 0,
      });
    });
    return out;
  },

  async getWebappMedias({ _ }, { path, since, withAttachments })
  {
    const out = [];
    const domain = path.substring(0, path.indexOf('/')) || '';
    const module = path.substring(path.indexOf('/') + 1) || '';
    const query = {
      subdomain: domain,
      moduleName: module,
    };
    if (since) query.startAt = since;
    const [data, err] = await firebaseReference.fetchAPI('https://captag.events/ext_api/getWallMessages/', { addTokenInQuery: true, query });
    if (!data || err)
    {
      console.error(`Error while fetching webapp medias: ${err}`);
      return [];
    }
    Object.values(data.details).forEach((dt) => {
      if (!withAttachments || (withAttachments && dt.attachment))
        out.push({
          link: dt.attachment,
          attachment: dt.attachment,
          message: dt.text,
          avatar: dt.avatar,
          author: dt.author,
          createdDate: dt.createdDate,
        });
    });
    return out;
  },

  async getUserdataMedias({ _ }, { data })
  {
    const out = [];
    const snapshot = await firebaseReference.ref.ref('guests').child(firebaseReference.eID).once('value');
    if (!snapshot.exists())
      return [];
    const guests = snapshot.val();
    Object.values(guests || {}).forEach((val) => {
      Object.entries(val.datas || {}).forEach(([dataID, dataVal]) => {
        if (data === dataID && dataVal.value)
          out.push({
            link: dataVal.value,
            attachment: dataVal.value,
            message: '',
            avatar: dataVal.value,
            author: dataVal.value,
            createdDate: '',
          });
      });
    });
    return out;
  },

  /**
   * Bind the social wall config to state.wallConfigData
   */
  bindWallConfig: firebaseAction(async function callback({ bindFirebaseRef })
  {
    console.log('Binding wall config');
    const fbRef = await firebaseReference.root;
    return bindFirebaseRef('wallConfigData', fbRef.child('config/live'));
  }),

  /**
   * Decode the TK parameter expected in the URL
   * once the token is decrypted the action loadTokenParams is dispatched
   * @param dispatch
   * @param commit
   */
  async decodeUrlParams({ dispatch, commit })
  {
    const searchParams = new URLSearchParams(window.location.search);
    const claims = await fbInit(searchParams.get('tk') || '');
    if (!claims)
    {
      commit('toggleLoadingError', { error: true, content: { code: 403, message: 'Veuillez vous rapprocher de votre contact chez Captag' } });
      return;
    }
    commit('setVersionNumber', { version, dbSync: false });
    dispatch('bindWallConfig').then(() => {
      dispatch('applyCustomCss');
      commit('toggleLoading', { value: false, dbSync: false });
    });
  },

  applyCustomCss({ getters })
  {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.id = 'customCSS';
    // remove the current customCSS if present
    if (document.getElementById('customCSS') != null)
      head.removeChild(document.getElementById('customCSS'));
    if (!getters.customCSS)
      return;
    const css = getters.customCss.replace('\n', '');
    head.appendChild(style);
    style.type = 'text/css';
    if (style.styleSheet)
      style.styleSheet.cssText = css;// This is required for IE8 and below.
    else
      style.appendChild(document.createTextNode(css));
    console.log('Updated custom CSS');
  },
};

export default actions;
