const mutations = {
  /**
   * Toggle the page loading screen
   * @param state state Vuex state (do not pass for users)
   * @param value: Boolean [true;false]
   */
  toggleLoading(state, { value })
  {
    state.loading = value;
  },

  /**
   * Toggle the error page
   * @param state state Vuex state (do not pass for users)
   * @param error: true to show the page
   * @param content: the error message
   */
  toggleLoadingError(state, { error, content })
  {
    state.loadingError = error;
    state.lastError = content;
  },

  /**
   * Set the version number.
   * This value is displayed on the bottom of the advanced configuration
   * @param state Vuex state (do not pass for users)
   * @param version: the version number, a string
   */
  setVersionNumber(state, { version })
  {
    state.versionNumber = version;
  },
};

export default mutations;
