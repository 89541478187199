<template>
  <div class="h-100" v-on:scroll.stop>
    <template v-if="isLoadingError">
      <div class="h-100 d-flex justify-content-center align-items-center align-middle">
        <div class="row">
          <div class="col-12 text-center">
            <h1>Configuration manquante ou invalide</h1>
          </div>
        </div>
      </div>
    </template>
    <LoadingPage v-else-if="isLoading"/>
    <div v-else class="h-100 container-fluid d-flex flex-column">
      <SocialWall v-if="wallType === 'socialwall'"/>
      <photowallGrid v-else-if="wallType === 'photowall'"/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import LoadingPage from './components/LoadingPage.vue';
import SocialWall from './components/socialWall/SocialWall.vue';
import PhotowallGrid from './components/photowall/photowallGrid.vue';

export default {
  name: 'App',
  components: {
    PhotowallGrid,
    SocialWall,
    LoadingPage,
  },
  mounted() {
    this.$store.dispatch('decodeUrlParams');
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'isLoadingError',
      'path',
      'wallType',
    ]),
  },
};

</script>

<style>

</style>
