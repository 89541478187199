<template>
  <div class="mosaic" style="overflow: hidden">
    <div class="forceAbsoluteTopLeft Back w-100 h-100" :style="backgroundStyle"></div>
    <img class="forceAbsoluteTopLeft Mask w-100 h-100" :src="backgroundImageSrc"/>
    <div id="Grid" class="forceAbsoluteTopLeft w-100 h-100">
      <table class="w-100 h-100">
        <tbody class="w-100 h-100">
          <tr v-for="y in gridSize.y" :key="`row_${y}`" class="grid-row">
            <td v-for="x in gridSize.x" :id="`cell_${getPositionFromCoord(x, y)}`" :key="`cell_${y}_${x}`" class="grid-cell">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <img v-if="maskImageSrc !== undefined && maskImageSrc !== ''" style="pointer-events : none;" class="forceAbsoluteTopLeft Mask w-100 h-100" v-bind:src="maskImageSrc"/>
    <div style="pointer-events : none;" id="spawnZone" class="forceAbsoluteTopLeft h-100 w-100 overflow-hidden">
      <div id="spawnPos0"/>
      <div id="spawnPos1"/>
      <div id="spawnPos2"/>
      <div id="spawnPos3"/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Vue from 'vue';
import _ from 'lodash';

export default {
  name: 'photowallGrid',
  data()
  {
    return {
      availableTiles: [],
      mediaStack: [],
      lastGet: 0,
      finished: false,
    };
  },
  mounted()
  {
    console.log('Computed update freq', this.photowallConfig.spawnFreq);
    this.getMedias().then(() => {
      // update de la pile de medias
      setInterval(() => this.getMedias(), 5000);
      // update des medias dans les colonnes
      setInterval(() => this.spawnMedias(), parseInt(this.photowallConfig.spawnFreq, 10));
    });
    this.resetImages();
  },
  computed: {
    ...mapGetters(['photowallConfig']),
    gridSize() {
      return {
        x: parseInt(this.photowallConfig.gridSize, 10),
        y: Math.ceil((this.photowallConfig.gridSize * 9) / 16),
      };
    },
    backgroundStyle()
    {
      return {
        backgroundColor: this.photowallConfig.backgroundColor.hex,
      };
    },
    backgroundImageSrc()
    {
      return this.photowallConfig.backgroundImage;
    },
    maskImageSrc()
    {
      return this.photowallConfig.maskImage;
    },
  },
  methods: {
    async getMedias()
    {
      const lastMedias = await this.$store.dispatch('getMedias', { since: this.lastGet, onlyImages: true });
      if (!lastMedias)
        return;
      lastMedias.forEach((media) => {
        this.mediaStack.push(_.cloneDeep(media));
      });
      this.lastGet = Date.now();
    },
    spawnMedias()
    {
      if (this.availableTiles.length <= 0)
      {
        if (this.photowallConfig.endBehavior === 'reload' && !this.finished)
        {
          this.finished = true;
          setTimeout(() => {
            this.resetImages();
            this.finished = false;
          }, parseInt(this.photowallConfig.finalTime || '5000', 10));
        }

        console.log('no more images');
        return;
      }
      if (this.mediaStack.length <= 0)
      {
        console.log('no media to show');
        this.lastGet = 0;
        return;
      }
      const media = this.mediaStack.shift();
      const tileID = this.getRandomInt(this.availableTiles.length);
      const cellID = this.availableTiles[tileID];
      const cellName = `cell_${this.availableTiles[tileID]}`;
      this.availableTiles.splice(tileID, 1);
      this.addImage(`spawnPos${this.getRandomInt(4)}`, media.link, cellName, cellID);
    },
    resetImages()
    {
      console.log('Reset images !');
      if (!this.photowallConfig.selectedCells)
        Vue.set(this.photowallConfig, 'selectedCells', []);
      this.photowallConfig.selectedCells.forEach((cell) => {
        const destinationElem = document.getElementById(`cell_${cell}`);
        if (destinationElem)
          destinationElem.style.backgroundImage = '';
      });
      this.availableTiles = this.photowallConfig.selectedCells.slice();
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    getPositionFromCoord(x, y)
    {
      return (y - 1) * this.gridSize.x + (x - 1);
    },
    addImage(spawnerID, imagePath, destination, tileID) {
      const destinationElem = document.getElementById(destination);
      const spawner = document.getElementById(spawnerID);
      const spawnZone = document.getElementById('spawnZone');
      const image = document.createElement('img');

      image.src = imagePath;
      destinationElem.style.backgroundImage = `url(${imagePath}`;
      Velocity(destinationElem, { opacity: 0 }, { duration: 0 });
      image.setAttribute('style', `height: ${spawnZone.clientHeight / 3}px;`);
      spawnZone.appendChild(image);

      image.setAttribute('style', `height: ${spawnZone.clientHeight / 3}px; position: absolute; top: ${spawner.offsetTop - (image.clientHeight / 2)}px; left: ${spawner.offsetLeft - (image.clientWidth / 2)}px;`);
      const moveSpeed = parseInt(this.photowallConfig.waitingTime, 10) + parseInt(this.photowallConfig.moveSpeed, 10) + parseInt(this.photowallConfig.fadeSpeed, 10);
      setTimeout(() => spawnZone.removeChild(image), moveSpeed);
      setTimeout(() => {
        Velocity(image, {
          left: destinationElem.offsetLeft,
          top: destinationElem.offsetTop,
          height: destinationElem.clientHeight,
          width: destinationElem.clientWidth,
        }, {
          duration: this.photowallConfig.moveSpeed, // temps de deplacement
        });
        setTimeout(() => {
          Velocity(image, {
            opacity: 0,
          }, {
            duration: this.photowallConfig.fadeSpeed, // fade out (doit etre comme le fade in)
          });
          Velocity(destinationElem, {
            opacity: 1,
          }, {
            duration: this.photowallConfig.fadeSpeed, // fade in (doit etre comme le fade out)
          });
        }, this.photowallConfig.moveSpeed);
      }, this.photowallConfig.waitingTime);// waiting time
    },
  },
};

</script>

<style scoped>

.forceAbsoluteTopLeft {
  position: absolute;
  top: 0px;
  left: 0px;
}

.Back {
  border: 0!important;
}

.Mask {
  border:0;
  text-decoration:none;
  outline:none;
}

#Grid {
  text-align: center;
}

.grid-cell {
  /*background-size: cover;*/
  /*background-repeat: no-repeat;*/
  /*background-position: center;*/
  background-size: 100% 100%;
  opacity: 0;
}

#spawnPos0 {
  position: absolute;
  top: 25%;
  left: 25%;
  height: 10px;
  width: 10px;
}

#spawnPos1 {
  position: absolute;
  top: 25%;
  right: 25%;
  height: 10px;
  width: 10px;
}

#spawnPos2 {
  position: absolute;
  bottom: 25%;
  left: 25%;
  height: 10px;
  width: 10px;
}

#spawnPos3 {
  position: absolute;
  bottom: 25%;
  right: 25%;
  height: 10px;
  width: 10px;
}

.final-enter-active {
  transition: opacity 3s ease-in-out;
}

</style>
