/** *******************************************
 *
 * CAPTAG'S FIREBASE ACCESS FILE
 * MADE BY CLEMENT LALU ON 03/2024 VERSION 1.0
 *
 * SCANMATCH / CAPTAG ALL RIGHTS RESERVED
 * MALOTRU
 *
 ******************************************** */

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Firebase database information
const firebaseDB1Config = {
  apiKey: 'AIzaSyBfONQeE8VLk1zWzJ5PDzAZEA1a7gl9Y2k',
  authDomain: 'captag-events.firebaseapp.com',
  databaseURL: 'https://captag-events-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'captag-events',
  storageBucket: 'captag-events.appspot.com',
  messagingSenderId: '418843808210',
  appId: '1:418843808210:web:52ea9222621c9d1c76f80b',
  measurementId: 'G-JFHBLW7WVF',
};

class FbReference {
  constructor()
  {
    this.fbCustomToken = '';
    this.apiToken = '';
    this.fb = undefined;
    this.userCredentials = undefined;
    this.eventID = '';
    this.animID = '';
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchTokenFromAPI(queryToken)
  {
    console.log('Requesting database tokens...');
    const fetchOption = {
      method: 'GET',
      headers: {
        authorization: `Bearer ${queryToken}`,
      },
    };
    const rep = await fetch('https://europe-west1-captag-events.cloudfunctions.net/ems/generateTokens', fetchOption);
    if (!rep.ok)
    {
      console.error('Error while querying QPI generateToken');
      return undefined;
    }
    console.log('...Done.');
    return rep.json();
  }

  async connectToFirebase()
  {
    if (this.fb && this.userCredentials)
      return (await this.userCredentials.user.getIdTokenResult(true)).claims || undefined;
    let out = undefined;
    const firebaseApp = firebase.initializeApp(firebaseDB1Config);
    try {
      this.userCredentials = await firebaseApp.auth().signInWithCustomToken(this.fbCustomToken);
      this.fb = firebaseApp.database();
      const claims = (await this.userCredentials.user.getIdTokenResult(true)).claims;
      if (claims && claims.animID && claims.eventID)
      {
        this.eventID = claims.eventID;
        this.animID = claims.animID;
      }
      out = claims;
      console.log('Logged in !');
    } catch (error) {
      console.log('Can\'t log in!', error);
      this.fb = undefined;
      return undefined;
    }
    return out;
  }

  async init(queryToken)
  {
    const tokens = await this.fetchTokenFromAPI(queryToken);
    if (!tokens)
      return undefined;
    this.fbCustomToken = tokens.customToken || '';
    this.apiToken = tokens.apiToken || '';
    return this.connectToFirebase();
  }

  get ref()
  {
    return this.fb || undefined;
  }

  get root()
  {
    return this.fb.ref('animations').child(this.eventID).child(this.animID);
  }

  get config() { return this.root.child('config'); }

  async fetchAPI(url, { query = {}, body = {}, method = 'GET', addTokenInQuery = false } = {})
  {
    const fetchOption = {
      method,
      headers: { authorization: `Bearer ${this.apiToken}` },
    };
    if (Object.keys(body).length > 0)
    {
      fetchOption['content-type'] = 'application/json';
      fetchOption.body = JSON.stringify(body);
    }
    if (addTokenInQuery)
      query.tk = this.apiToken;
    let fullUrl = url;
    if (Object.keys(query).length > 0)
    {
      fullUrl = `${fullUrl}${url.includes('?') ? '&' : '?'}`;
      Object.entries(query).forEach(([key, value]) => {
        fullUrl = `${fullUrl}${key}=${value}&`;
      });
      fullUrl = fullUrl.substring(0, fullUrl.length - 1);
    }
    const rep = await fetch(fullUrl, fetchOption);
    const repBody = await rep.json();
    if (!rep.ok)
      return [undefined, repBody ? repBody.message || '' : ''];
    return [repBody, ''];
  }

  get rootDatabaseUrl()
  {
    return `${firebaseDB1Config.databaseURL}/animations/${this.eventID || ''}/${this.animID || ''}`;
  }

  get eID() { return this.eventID; }

  get aID() { return this.animID; }
}

const fbReferenceClass = new FbReference();

export async function fbInit(queryToken)
{
  return fbReferenceClass ? fbReferenceClass.init(queryToken) : undefined;
}

export const fbTimestamp = firebase.database.ServerValue.TIMESTAMP;

export default fbReferenceClass;
