<template>
  <div class="row h-100 pt-4 px-2" :style="backgroundStyle">
    <transition-group v-for="(col, colIdx) in columnsPosts" :key="`column-${colIdx}`" name="list-complete" tag="div" class="col">
      <SocialWallCard v-for="post in col" :key="`post-${post.postID}`" :postData="post" class="list-complete-item mb-4" :style="`--duration: ${animationSpeed}s`" :cardStyle="socialwallConfig"/>
    </transition-group>
  </div>
</template>

<script>

import Vue from 'vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import SocialWallCard from './SocialWallCard.vue';

export default {
  name: 'SocialWall',
  components: { SocialWallCard },
  data()
  {
    return {
      lastGet: 0,
      mediaStack: [],
      columnsPosts: [],
    };
  },
  created()
  {
    for (let i = 0; i < this.columnsCount; i = i + 1)
      Vue.set(this.columnsPosts, i, []);
  },
  mounted()
  {
    const compUpdateFreq = this.updateFreq * 1000;
    const compColFreq = Math.ceil(compUpdateFreq * 0.65);
    console.log('Computed update freq', compUpdateFreq, compColFreq);
    this.getMedias().then(() => {
      // update de la pile de medias
      setInterval(() => this.getMedias(), compUpdateFreq);
      // update des medias dans les colonnes
      setInterval(() => this.putMediasInColumns(), compColFreq);
    });
  },
  computed: {
    ...mapGetters(['socialwallConfig']),
    columnsCount()
    {
      return this.socialwallConfig.columnsCount || 3;
    },
    backgroundImage()
    {
      return this.socialwallConfig.backgroundImage || '';
    },
    backgroundStyle()
    {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        backgroundColor: this.socialwallConfig.backgroundColor ? this.socialwallConfig.backgroundColor.hex : 'white',
      };
    },
    updateFreq()
    {
      return this.socialwallConfig.updateFreq || 5;
    },
    animationSpeed()
    {
      return this.socialwallConfig.animationSpeed || 2;
    },
  },
  methods: {
    getRandomInt(min, max)
    {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },
    putMediasInColumns()
    {
      // console.log('push media to a col');
      if (this.mediaStack.length <= 0)
      {
        this.lastGet = 0;
        return;
      }
      const media = this.mediaStack.shift();
      const columnIdx = this.getRandomInt(0, this.columnsCount);
      if (!this.columnsPosts[columnIdx])
        Vue.set(this.columnsPosts, columnIdx, []);
      media.postID = this.$chance.string({
        length: 10,
        casing: 'upper',
        alpha: true,
        numeric: false,
      });
      this.columnsPosts[columnIdx].splice(0, 0, _.cloneDeep(media));
      if (this.columnsPosts[columnIdx] && this.columnsPosts[columnIdx].length > 15)
      {
        this.columnsPosts[columnIdx].splice(this.columnsPosts[columnIdx].length - 1, 1);
      }
    },
    async getMedias()
    {
      // console.log('Get medias since ', this.lastGet);
      const lastMedias = await this.$store.dispatch('getMedias', { since: this.lastGet, onlyImages: false });
      if (!lastMedias)
        return;
      lastMedias.forEach((media) => {
        if (!this.socialwallConfig.displayMessage && !media.attachment)
          return;
        this.mediaStack.push(_.cloneDeep(media));
      });
      this.lastGet = Date.now();
    },
  },
};

</script>

<style>

.list-complete-item {
  --duration: 2s;
  transition: all var(--duration);
}
.list-complete-enter
  /* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-100%);
}
/*.list-complete-leave-active {*/
/*  position: absolute;*/
/*}*/

</style>
