<template>
  <div class="card" style="border-radius: 5px;" :style="cardBodyStyle">
    <img
      v-if="attachment !== ''"
      :src="attachment"
      class="card-img-top"
      alt="post attachement"
      :style="imageBorderStyle">
    <div class="card-body" v-if="isDisplayCardBody">
      <h3 v-if="cardStyle.displayMessage && message" :class="messageAlignment" :style="messageStyle" class="card-text" v-html="message"></h3>
      <div v-if="(cardStyle.displayAvatar || cardStyle.displayAuthor || cardStyle.displayTime) && (author || avatar)" class="row">
        <div v-if="cardStyle.displayMessage" class="col-12"><hr></div>
        <div v-if="cardStyle.displayAvatar && avatar" class="col-auto"><img style="border-radius: 50%;width: 55px;" class="mx-auto" :src="avatar" alt=""></div>
        <div :class="authorAlignment" class="col d-flex align-items-center"><h4 :style="authorStyle" class="w-100"><span v-if="cardStyle.displayAuthor && author !== ''">{{ author }}</span><br><span v-if="cardStyle.displayTime" :style="dateSize">{{ time | formatDate }}</span></h4></div>
      </div>
    </div>
  </div>
</template>

<script>

const moment = require('moment');

export default {
  name: 'SocialWallCard',
  props: {
    postData: {
      type: Object,
      required: true,
    },
    cardStyle: {
      type: Object,
      required: true,
    },
  },
  filters: {
    /**
     * Date formatting.
     * this use the moment package to do that
     * val is a ms unix timestamp
     */
    formatDate(val) {
      if (!val) {
        return '-';
      }
      return moment(val).locale('fr').calendar();
    },
  },
  computed: {
    isDisplayCardBody()
    {
      if (!this.cardStyle.displayMessage &&
        !this.cardStyle.displayAvatar &&
        !this.cardStyle.displayAuthor &&
        !this.cardStyle.displayTime)
        return false;
      return ((this.avatar && this.cardStyle.displayAvatar) ||
      (this.author && this.cardStyle.displayAuthor) ||
      (this.message && this.cardStyle.displayMessage));
    },
    imageBorderStyle()
    {
      const out = {};
      out.borderTopLeftRadius = `${this.cardStyle.borderRadius || 0}px`;
      out.borderTopRightRadius = `${this.cardStyle.borderRadius || 0}px`;
      if (!this.isDisplayCardBody)
      {
        out.borderBottomRightRadius = `${this.cardStyle.borderRadius || 0}px`;
        out.borderBottomLeftRadius = `${this.cardStyle.borderRadius || 0}px`;
      }
      return out;
    },
    cardBodyStyle()
    {
      const out = {};
      if (this.cardStyle.cardBorder)
        out.border = `${this.cardStyle.cardBorder.thickness || 2}px ${this.cardStyle.cardBorder.style || 'solid'} ${this.cardStyle.cardBorder.color.hex || 'black'}!important`;
      if (this.cardStyle.borderRadius)
        out.borderRadius = `${this.cardStyle.borderRadius || ''}px`;
      if (this.cardStyle.cardBackgroundColor)
        out.backgroundColor = this.cardStyle.cardBackgroundColor ? this.cardStyle.cardBackgroundColor.hex : 'white';
      return out;
    },
    attachment()
    {
      return this.postData.attachment || '';
    },
    message()
    {
      const reg = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      let out = this.postData.message;
      out = out.replaceAll(reg, '<a style="text-decoration: underline" class="text-muted small" href="#">Click here to watch the video</a>');
      out = out.trim();
      return out || '';
    },
    messageAlignment()
    {
      if (!this.cardStyle.messageAlignment)
        return 'text-left';
      switch (this.cardStyle.messageAlignment)
      {
        case 'left':
          return 'text-left';
        case 'center':
          return 'text-center';
        case 'right':
          return 'text-right';
        default:
          return 'text-left';
      }
    },
    authorAlignment()
    {
      if (!this.cardStyle.authorInfoAlignment)
        return 'text-left';
      switch (this.cardStyle.authorInfoAlignment)
      {
        case 'left':
          return 'text-left';
        case 'center':
          return 'text-center';
        case 'right':
          return 'text-right';
        default:
          return 'text-left';
      }
    },
    dateSize()
    {
      return {
        fontSize: `${(this.cardStyle.authorFontSize || 22) * 0.75}px`,
      };
    },
    authorStyle()
    {
      const out = {};
      out.fontSize = `${this.cardStyle.authorFontSize || 22}px`;
      out.color = `${this.cardStyle.authorColor.hex8 || (this.cardStyle.authorColor.hex || 'black')}`;
      return out;
    },
    messageStyle()
    {
      return {
        fontFamily: `${this.cardStyle.fontFamily || ''}`,
        fontSize: `${this.cardStyle.messageFontSize || 22}px`,
        color: `${this.cardStyle.messageColor.hex8 || (this.cardStyle.messageColor.hex || 'black')}`,
        wordBreak: 'break-all',
      };
    },
    avatar()
    {
      return this.postData.avatar || '';
    },
    author()
    {
      return this.postData.author || '';
    },
    time()
    {
      return this.postData.createdDate || 0;
    },
  },
};

</script>

<style scoped>

</style>
