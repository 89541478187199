import Vue from 'vue';
import App from './App.vue';
import store from './store';

const Chance = require('chance');

Vue.config.productionTip = false;

Vue.prototype.$chance = new Chance();

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
